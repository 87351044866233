.ChallengeCard {
    width: fit-content;
}

.Figure {
    margin-bottom: 0;
    font-size: large;
}
.FigureCaption {
    color: #0b0000;
}
@media only screen and (max-width: 600px) {
    .JoinBtn {
        margin-top: 10%;
    }
}
