.CompetitionSubmissionPhase {
    background-color: #96E1FF;
}

.SandboxSubmissionPhase {
    background-color: #D6F3FF;
}

.OtherSubmissionPhase {
    background-color: white;
}