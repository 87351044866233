.BiggerChallengeCard {
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 18rem;
    max-width: 18rem;
}

@media only screen and (max-width: 600px) {
     .BiggerChallengeCard {
         min-width: 15rem;
    }
}

.BiggerChallengeCard:hover {
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-top: 20px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05) !important;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
}

.SmallerChallengeCard {
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 15rem;
    max-width: 18rem;
}
.SmallerChallengeCard:hover {
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-top: 20px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05) !important;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
}

.ChallengeTitle {
    font-size: 95%;
    margin-top: 5%;
}
.ChallengeDescription{
    font-size: 80%;
}
