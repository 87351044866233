.DarkerPart{
    padding-top: 3%;
    background-color: #f2f2f2;
}

.LighterPart{
    padding-top: 3%;
    margin-bottom: 5%;
}

.OrganizersSection {
    margin-bottom: 8%;
}
