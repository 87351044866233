.ContentSideBarExpanded {
    margin-top: 3%;
    margin-left: 15%;
    margin-right: 6%;
}
.ContentSideBarCollapsed {
    margin-top: 3%;
    margin-left: 12%;
    margin-right: 13%;
}

@media only screen and (max-width: 600px) {
    .ContentSideBarCollapsed {
        margin-top: 15%;
        margin-left: 20%;
        margin-right: 15%;
    }
    .ContentSideBarExpanded {
        margin-top: 15%;
        margin-left: 15%;
        margin-right: 6%;
    }
}

.AuthButton{
    right: 1%;
    position: fixed;
    background-color: #212529;
    text-align: center;
    color: whitesmoke !important;
    top: 1%;
    border: #212529;
    font-weight: 600;
}

.AuthButton a{
    color: whitesmoke !important;
}

.AuthButton a:hover{
    background-color: #343a40;
    color: whitesmoke !important;
}
.AuthButton:hover{
    background-color: #343a40;
    color: whitesmoke !important;
}