.navbar-dark .navbar-brand {
    color: rgb(121, 217, 255);
    font-size: x-large;
}
.navbar-dark .navbar-nav .nav-link {
    color: whitesmoke;
}
.navbar-dark .navbar-nav .nav-link:hover {
    color: grey;
}

.navbar-nav .btn a {
    color: #212529;
    text-decoration: none;
}

.navbar-nav .btn a:hover {
    font-weight: 600;
    color: #212529;
    text-decoration: none;
    background-color: transparent !important;
}


.navbar-nav .btn {
    font-weight: 600;
}
