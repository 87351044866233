.Conference {
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-top: 20px;
    margin-bottom: 20px;
}
.Button {
    background-color: rgb(0, 195, 255);
    border-color: rgb(0, 195, 255);
    border-radius: 8px;
    background-image: none;
}
.Button:hover {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}