.CardDeck {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2%;
}

@media only screen and (max-width: 600px) {
     .CardDeck {
        display: block;
        justify-content: center;
        margin-bottom: 2%;
    }
}

.ChallengesHeader {
    margin-bottom: 2%;
    font-weight: bold;
}