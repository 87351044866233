.NewsRow {
    overflow-x: auto;
}

.NewsHeaderCol {
    text-align: center;
}

.HomeNewsHeader{
    margin-bottom: 1%;
    font-weight: bold;
    align-content: center;
}