.Header {
    text-align: center;
}
.Title {
    font-weight: 800;
    font-size: xxx-large;
}

@media only screen and (max-width: 600px) {
    .Title {
        font-weight: bold;
        font-size: medium;
    }
}

.HeaderIcon {
    text-align: center;
}

.Icon {
    display: inline-block;
    vertical-align: middle;
}

.OuterLine {
    width: 40%;
    border-bottom: 4px solid black;
}

@media only screen and (max-width: 600px) {
   .OuterLine {
        width: 33%;
        border-bottom: 4px solid black;
    }
}
