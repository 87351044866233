.SubmissionCard {
    width: 100%;
}

.Figure {
    margin-bottom: 0;
    font-size: large;
}

.FigureCaption {
    color: #0b0000;
}

.SubmissionContent {
    margin-top: 3%;
}
