.FileUploadDrop,
.FileUploadDropInvalid,
.FileUploadDropValid
 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 140px;
    border: 4px dashed #5baaf5;
}

.FileUploadDrop:hover { 
    border: 4px dashed#0083fd;
    background-color: rgba(0, 0, 0, 0.03) !important;
}

.FileUploadDropInvalid {
    border: 4px dashed rgb(240, 90, 100);
}

.FileUploadDropInvalid:hover {
    border: 4px dashed rgb(220, 50, 70);
    background-color: rgba(0, 0, 0, 0.03) !important;
}

.FileUploadDropValid {
    border: 4px dashed rgb(90, 180, 110);
}

.FileUploadDropValid:hover {
    border: 4px dashed rgb(40, 160, 70);
    background-color: rgba(0, 0, 0, 0.03) !important;
}
