.TagCard {
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 15rem;
    max-width: 18rem;
}

.TagCard:hover {
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-top: 20px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05) !important;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
}

.TagTitle {
    font-size: 95%;
    margin-top: 5%;
}

.TagDescription{
    font-size: 80%;
}
