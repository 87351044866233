.Logo {
    height: 14%;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    position: absolute;
    margin-bottom: 0px;
    bottom:2%;
    left:7%
}

.Logo img {
    height: 100%;
}

.Invisible {
    display: none;
}



/* @media (min-width: 500px) {  
    .Logo {
        display: none;
    }
}  */

 /* @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { 
    .selector:not(*:root), .Logo {
        left:20%
    }
}  */
@media screen and (-webkit-min-device-pixel-ratio:0)
{ 
    .selector:not(*:root), .Logo {
        left:20%;
    }
}
@-moz-document url-prefix() {
    .Logo {
        left:7%;
    }
  }