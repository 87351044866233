.HomeHeader {
    font-weight: 600;
    margin-bottom: 6%;
}

.HomeButton {
    background-color: rgb(0, 195, 255);
    border-color: rgb(0, 195, 255);
    border-radius: 8px;
    background-image: none;
    margin-top: 5%;
}

@media only screen and (max-width: 600px) {
    .HomeButton {
        margin-bottom: 5%;
    }
}

.HomeButton:hover {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}
.HomeSection {
    margin-bottom: 8%;
}

.HomeCurrentCompHeader {
    margin-bottom: 2%;
    font-weight: bold;
}

.DarkerPart{
    padding-top: 3%;
    background-color: #f2f2f2;
}

.LighterPart{
    padding-top: 3%;
    margin-bottom: 5%;
}

.CompCardDeck {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2%;
}

@media only screen and (max-width: 600px) {
    .CompCardDeck {
        display: block;
    }
}
.CurrentCompCard {
    border-radius: 1px;
    border:none;
    min-width: 25rem;
    text-align: center;
    background-color: rgb(121, 217, 255);
    margin-top: 3%;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}
@media only screen and (max-width: 600px) {
    .CurrentCompCard {
        min-width: auto;
    }
}

.CurrentCompLeftCard:hover {
    color: white;
    transform: translateX(8px);
}

.CurrentCompRightCard:hover {
    color: white;
    transform: translateX(-8px);
}

.CurrentCompetitionButton{
    color: whitesmoke;
    background-color: grey;
    border: whitesmoke;
    min-width: 30%;
    font-weight: bold;;
}

.CurrentCompetitionButton:hover{
    color: whitesmoke;
    background-color: darkgrey;
    border: whitesmoke;
    min-width: 30%;
    font-weight: bold;;
}

.CurrentCompetitionText {
    color: white;
    font-weight: 600;
    font-size: xx-large;
}

.DatasetText {
    text-align: right;
}

.RecentDatasetCard{
    max-width: 23rem;
    margin-bottom: 2%;
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    margin-top: 20px;
}

.RecentDatasetCard:hover{
    cursor: pointer;
    border-radius: 1px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.05) !important;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
}
.RecentDataset{
    display: flex;
}

.RecentDatasetText {
    padding:  4% 1% 0 4% ;
}

.RecentDatasetIcon {
    margin-top: 10%;
    margin-right: 2%;
}

.CommunityHeaderCol {
    text-align: center;
}

.HomeCommunityHeader{
    margin-bottom: 1%;
    font-weight: bold;
    align-content: center;
}

.CommunitySubHeader {
    font-weight: bold;
    color: rgb(0, 195, 255);
}

.CommunityImage{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.CommunityImageCol{
    margin-top: 5%;
    padding: 0 !important;
}

@media only screen and (max-width: 600px) {
    .CommunityImage {
        display: none;
    }
}
@media only screen and (min-width: 600px) and  (max-width: 758px) {
    .CommunityRectangular {
        position: absolute;
        padding: 2%;
        width: fit-content;
        height: fit-content;
        margin-bottom: 2%;
        font-size: smaller;
    }
    .CommunityRectHeader {
        font-size: small;
    }
    .HeartIcon {
        font-size: 1.25rem;
    }
    .CommunityRectText {
        display: none;
    }
}

.CommunityRectangular {
    display: flex;
    border-radius: 1px;
    border-right: 0;
    padding: 20px;
    width: 80%;
    height: 20%;
    position: absolute;
}

@media only screen and (max-width: 600px) {
    .CommunityRectangular {
        position: relative;
        padding: 2%;
        width: fit-content;
        height: fit-content;
        top:0 !important;
        bottom:0 !important;
        margin-bottom: 2%;
    }
    .CommunityRectHeader{
        font-size: small;
    }
    .HeartIcon {
        font-size: 1.25rem;
    }
}

@media only screen and (min-width: 992px) {
    .CommunityRectangular {
        width: fit-content;
        height: fit-content;
        margin-bottom: 0;
    }
    .CommunityRectHeader{
        font-size: medium;
    }
    .HeartIcon {
        font-size: 1.25rem;
    }
}

@media only screen and (min-width: 768px) {
    .CommunityRectangular {
        width: fit-content;
        height: fit-content;
        padding: 0.5rem;
    }
    .CommunityRectHeader{
        font-size: medium;
    }
    .HeartIcon {
        font-size: 1.25rem;
    }
}

.CommunityRectangular:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.CommunityRectHeader {
    font-size: large;
}

.CommunityRectangular1 {
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.26);
    right: 0;
    top: 20%;
}

.CommunityRectangular2 {
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.26);
    right: 0;
    top: 60%;
}

.CommunityRectangular3 {
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.26);
    left: 0;
    top: 30%;
}

.CommunityRectangular4 {
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.26);
    left: 0;
    top: 70%;
}

.CommunityRectHeader{
    font-weight: bold;
}

.HeartIcon {
    font-size: 1.75rem;
    color: #ee3162;
}

.HeartLeftIcon {
    padding-right: 0.7rem;
}

.HeartRightIcon {
    padding-left: 0.7rem;
}